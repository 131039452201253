<template>
  <header class="bg-background bg-slate-800 border-b px-6 py-4 flex justify-between">
    <h1 class="text-2xl font-bold text-white">LogBase 更新日志</h1>
  </header>

  <div class="flex flex-col h-full items-center">
    <div class="flex-1 p-6 space-y-4">
      <div class="flex items-center mb-4">
        <div class="relative flex-1">
          <!-- 搜索框 -->
          <input class="flex h-10 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 pl-8 w-full" placeholder="搜索关键词..." type="search" v-model="searchQuery" />
        </div>
        <!-- 排序按钮 -->
        <div class="relative ml-4">
          <button @click="toggleDropdown" class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
            排序
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4 ml-2">
              <path d="m21 16-4 4-4-4"></path>
              <path d="M17 20V4"></path>
              <path d="m3 8 4-4 4 4"></path>
              <path d="M7 4v16"></path>
            </svg>
          </button>
          <!-- 排序选项 -->
          <div v-if="isDropdownOpen" class="absolute right-0 mt-2 bg-white shadow-lg rounded-md z-10 border border-gray-300">
            <ul class="text-left w-32">
              <li @click="sortBy('file')" class="text-sm cursor-pointer px-4 py-2 hover:bg-gray-100">文件名</li>
              <li @click="sortBy('date')" class="text-sm cursor-pointer px-4 py-2 hover:bg-gray-100">发布日期</li>
              <li @click="sortBy('platform')" class="text-sm cursor-pointer px-4 py-2 hover:bg-gray-100">系统</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 表格 -->
      <div class="relative shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 table-auto">
          <thead class="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-4 py-3">文件名</th>
              <th scope="col" class="px-4 py-3">发布日期</th>
              <th scope="col" class="px-4 py-3">系统</th>
              <th scope="col" class="px-4 py-3"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="this.all_patch_info.length === 0">
              <td colspan="6" class="px-4 py-4 text-center text-gray-500">
                数据获取中
                <i class="three-dots-loading" style="vertical-align: middle;" />
              </td>
            </tr>
            <tr v-for="patch_info in paginatedPatchInfo" :key="patch_info.file">
              <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap">{{ patch_info.file }}</th>
              <td class="px-4 py-2">{{ patch_info.date }}</td>
              <td class="px-4 py-2">{{ patch_info.platform }}</td>
              <td class="px-6 py-4 text-black">
                <a 
                  type="button" 
                  class="material-symbols--note mr-2" 
                  title="查看日志"
                  :id="'btn_note_' + patch_info.file"
                  @click="getNote(patch_info.file)" 
                  target="_blank"
                ></a>
                <a 
                  type="button" 
                  class="material-symbols--link" 
                  title="转到链接"
                  :href="patch_info.link"
                  target="_blank"
                ></a>
              </td>
            </tr>
            <!-- 分页控制 -->
            <div class="flex justify-between items-center mt-4 mb-2">
              <button @click="prevPage" :disabled="currentPage === 1" class="ml-3 px-4 py-2 carbon--previous-filled">上一页</button>
              <span>第 {{ currentPage }} 页，共 {{ totalPages }} 页</span>
              <button @click="nextPage" :disabled="currentPage === totalPages" class="mr-3 px-4 py-2 carbon--next-filled">下一页</button>
              <!-- scan date -->
              <div>
                  <span>更新时间：<span>{{ this.scanDate }}</span></span>
              </div>
            </div>
          </tbody>
        </table>
      </div>
    </div>
  </div>
    <!-- 模态弹窗 -->
    <CustomModal :isOpen="isModalVisible" @close="closeModal" :title="modalTitle" :content="modalContent" :version="modalVersion">
    </CustomModal>
</template>



<script>
import api from '../api'; // 导入封装好的API模块
import CustomModal from './CustomModal.vue'; // 确保路径和名称正确

export default {
  components: {
    CustomModal,
  },
  data() {
    return {
      all_patch_info: [],
      searchQuery: '',
      sortByField: '',
      isDropdownOpen: false,
      currentPage: 1,
      itemsPerPage: 10,
      isModalVisible: false, // 控制模态弹窗的显示和隐藏
      scanDate: 'unknow',
    };
  },
  computed: {
    filteredPatchInfo() {
      return this.all_patch_info.filter(patch_info => {
        return (
          patch_info.file.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          patch_info.date.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          patch_info.platform.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },
    sortedPatchInfo() {
      return this.sortPatchInfo(this.filteredPatchInfo);
    },
    paginatedPatchInfo() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.sortedPatchInfo.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.sortedPatchInfo.length / this.itemsPerPage);
    }
  },
  methods: {
    openModal(title, version, content) {
      this.modalTitle = title; // 设置标题
      this.modalContent = content; // 设置内容
      this.modalVersion = version;
      this.isModalVisible = true; // 显示模态弹窗
    },
    closeModal() {
      this.isModalVisible = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    sortBy(field) {
      this.sortByField = field;
      this.isDropdownOpen = false;
      this.jumpToFirstPage(); // 每次排序后跳到第一页
    },
    sortPatchInfo(patchInfo) {
      if (this.sortByField === '') return patchInfo;
      return patchInfo.slice().sort((a, b) => {
        if (this.sortByField === 'date') {
          return new Date(b[this.sortByField]) - new Date(a[this.sortByField]);
        }
        return a[this.sortByField].localeCompare(b[this.sortByField]);
      });
    },
    jumpToFirstPage() {
      this.currentPage = 1;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async getPatch() {
      const response = await api.getPatch(); 
      if (response.data.result && response.data.data.length > 0) {
        this.searchQuery = '';
        this.all_patch_info = response.data.data;  // 更新数据
        this.scanDate = response.data.scan_date
        this.jumpToFirstPage(); // 数据更新后跳到第一页
      } else {
        console.log("data length: 0");
        this.all_patch_info = [];
        alert('未查询到相关数据');
      }
    },
    async getNote(file) {
      // set button disabled
      var btn_element = document.getElementById('btn_note_'+file)
      btn_element.disabled = true; // 设置按钮为禁用状态
      btn_element.style.cursor = 'not-allowed'; // 设置鼠标指针为不可选中状态
      btn_element.classList.add('rotate'); // 添加旋转效果

      var data = {file: file}
      const response = await api.getNote(data); 
      var title = '更新日志';
      var version = file;
      var content = '';
      if (response.data.result && response.data.data) {
        // 弹窗
        content += response.data.data;
      } else {
        console.log("data length: 0");
        content += '未找到更新日志'
      }
      this.openModal(title, version, content);
      btn_element.disabled = false; // 设置按钮为启用状态
      btn_element.style.cursor = 'pointer'; // 设置鼠标指针为可选中状态
      btn_element.classList.remove('rotate'); // 移除旋转效果
    },
  },
  watch: {
    searchQuery() {
      this.jumpToFirstPage(); // 每次搜索时跳到第一页
    }
  },
  mounted() {
    this.getPatch();
  }
};
</script>


<style src="../style/styles.css"></style>
